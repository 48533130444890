import api from '@/utils/api.js';
import { ref } from 'vue';
import { deserializeJsonApi } from "@/utils/jsonApiDeserializer.js";
import { createGlobalState } from '@vueuse/core'

export const useAuthUserState = createGlobalState(() => {
  const name = ref('');
  const avatar = ref('');
  const draftItinerariesCount = ref(null);
  const cmsEnabled = ref(false);

  async function fetch() {
    const { data } = await api.get('/api/user');
    const user = data ? await deserializeJsonApi(data) : {};

    if (Object.keys(user).length > 0) {
      name.value = user.name;
      avatar.value = user.avatar;
      draftItinerariesCount.value = user.meta.draftItinerariesCount;
      cmsEnabled.value = user.meta.cmsEnabled;
      return user;
    }

    return null;
  }

  return { fetch, name, avatar, cmsEnabled, draftItinerariesCount }
})
